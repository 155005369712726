import _debounce from "lodash/debounce";
import { ImagePreview } from 'vant';
import common from '@mixins/common';
import filters from '@filters/filters';
import { formatPx2Rem, isJSON } from '@utils/index';
import { isEmpty, dateFilter } from '@utils/utils';
import { statusMap } from '@/dictionaries/custom';
import { getDetailAPI, submitCustomAPI } from '@api/custom';
import img002 from '@assets/images/img_002.png';
import img003 from '@assets/images/img_003.png';
export default {
  name: 'CustomDetail',
  mixins: [common, filters],
  components: {},
  data() {
    return {
      img002,
      img003,
      headerTitle: '定制详情',
      clientInfo: {
        // 客户信息
        baseInfoFields: [
        // 基本信息
        {
          title: '姓名',
          key: 'contacts',
          type: 'value',
          icon: {
            type: 'svg',
            svgName: 'detail_name'
          },
          color: 'default'
        }, {
          title: '联系方式',
          key: 'phone',
          type: 'value',
          icon: {
            type: 'svg',
            svgName: 'detail_phone'
          },
          color: 'default'
        }, {
          title: '提交时间',
          key: 'createTime',
          type: 'value',
          icon: {
            type: 'svg',
            svgName: 'detail_time'
          },
          color: 'default'
        }],
        baseInfo: {}
      },
      serviceInfo: {
        // 服务评价
        rateInfoFields: [
        // 评价
        {
          title: '管家响应：',
          key: 'fractionEfficiency'
        }, {
          title: '服务态度：',
          key: 'fractionAttitude'
        }, {
          title: '服务品质：',
          key: 'fractionQuality'
        }],
        rateInfo: {}
      },
      statusMap,
      isLoading: false,
      // 是否在加载数据
      isRefreshPage: false,
      // 是否需要刷新页面
      noDataMessage: '',
      // 列表为空时的提示文案
      detailInfo: {},
      // 详情信息
      demandInfo: {
        // 需求备注
        remark: '',
        videoFilesList: [],
        imgFilesList: []
      },
      videoPopupInfo: {
        // 视频播放
        show: false,
        options: {}
      }
    };
  },
  props: {
    id: {
      type: [Number, String],
      default: ''
    }
  },
  computed: {},
  methods: {
    formatPx2Rem,
    // 日期格式化
    dateFilter,
    // 空字段过滤
    fieldEmptyFilter(data, returnEmpty = '无') {
      return !isEmpty(data) ? data : returnEmpty;
    },
    // 格式化基本信息
    formatBaseInfo() {
      let {
        detailInfo
      } = this;
      this.clientInfo.baseInfo = {
        contacts: this.fieldEmptyFilter(detailInfo.contacts),
        phone: this.fieldEmptyFilter(detailInfo.phone),
        createTime: this.fieldEmptyFilter(detailInfo.createTime)
      };
    },
    // 格式化需求备注
    formatDemandInfo() {
      let {
        detailInfo
      } = this;
      let fileList = isJSON(detailInfo.fileList) ? JSON.parse(detailInfo.fileList) : [];
      this.demandInfo.remark = this.fieldEmptyFilter(detailInfo.remark);
      this.demandInfo.videoFilesList = fileList.filter(item => `${item.type}` === '1');
      this.demandInfo.imgFilesList = fileList.filter(item => `${item.type}` === '0');
    },
    // 格式化评分信息
    formatRateInfo() {
      let {
        detailInfo
      } = this;
      this.serviceInfo.rateInfo = {
        fractionEfficiency: this.fieldEmptyFilter(detailInfo.fractionEfficiency, 0),
        fractionAttitude: this.fieldEmptyFilter(detailInfo.fractionAttitude, 0),
        fractionQuality: this.fieldEmptyFilter(detailInfo.fractionQuality, 0)
      };
    },
    // 视频播放
    handleVideoPreview(data) {
      this.videoPopupInfo.options = {
        url: data.url,
        fitVideoSize: 'fixWidth',
        videoInit: true
      };
      this.videoPopupInfo.show = true;
      this.$nextTick(() => {
        this.$refs.cyuiPlayer.player.play();
      });
    },
    // 暂停视频播放
    handleVideoPause() {
      this.$nextTick(() => {
        this.$refs.cyuiPlayer.player.pause();
      });
    },
    handleImagePreview(options = {}, images = []) {
      ImagePreview({
        images: images.map(item => item.url),
        closeable: true,
        ...options
      });
    },
    handleSubmit: _debounce(function () {
      this.$dialog.confirm({
        title: '完成服务',
        message: '是否确认完成服务？',
        confirmButtonColor: '#D8B084'
      }).then(async () => {
        // on confirm
        await this.submitCustomData();
      }).catch(() => {
        // on cancel
      });
    }, 200),
    async handleCallback({
      isError,
      errorMessage = undefined
    }) {
      // 回调处理
      if (isError) {
        this.$toast(errorMessage || '获取失败!');
        /* if (errorMessage && errorMessage.includes('timeout')) {
          // 判断是否是网络请求超时
          this.isRefreshPage = true
          this.noDataMessage = '网络请求超时'
        } else if (errorMessage && errorMessage.includes('502')) {
          // 判断是否是服务器错误
          this.isRefreshPage = true
          this.noDataMessage = '服务器错误，502错误'
        } */
      }
    },

    async submitCustomData({
      isShowLoading
    } = {
      isShowLoading: true
    }) {
      // 完成接待
      try {
        this.isLoading = true;
        if (isShowLoading) await this.$store.dispatch('base/SetLoading', true);
        let {
          id
        } = this;
        let response = await submitCustomAPI({
          id
        });
        let result = response.data;
        // console.log(result)
        if (`${result.code}` === '10000') {
          // 请求成功
          // 接口请求处理成功之后回跳
          this.$toast({
            message: '提交成功！',
            onClose: () => {
              this.$router.go(-1);
            }
          });
        } else {
          // 请求失败
          await this.handleCallback({
            isError: true,
            errorMessage: result.msg || '完成接待失败!'
          });
        }
      } catch (error) {
        // console.log(error)
        await this.handleCallback({
          isError: true,
          errorMessage: error.message || '完成接待失败!'
        });
      } finally {
        this.isLoading = false;
        if (isShowLoading) await this.$store.dispatch('base/SetLoading', false);
      }
    },
    async getDetailData({
      isShowLoading
    } = {
      isShowLoading: true
    }) {
      // 获取详情数据
      try {
        this.isLoading = true;
        if (isShowLoading) await this.$store.dispatch('base/SetLoading', true);
        let {
          id
        } = this;
        let response = await getDetailAPI({
          id
        });
        let result = response.data;
        // console.log(result)
        if (`${result.code}` === '10000') {
          // 请求成功
          let resultData = result.data || {};
          this.detailInfo = resultData;
          this.fileList = isJSON(this.detailInfo.fileList) ? JSON.parse(this.detailInfo.fileList) : [];
          this.formatBaseInfo();
          this.formatDemandInfo();
          this.formatRateInfo();
          await this.handleCallback({
            isError: false
          });
        } else {
          // 请求失败
          await this.handleCallback({
            isError: true,
            errorMessage: result.msg || '获取列表失败!'
          });
        }
      } catch (error) {
        // console.log(error)
        await this.handleCallback({
          isError: true,
          errorMessage: error.message || '获取列表失败!'
        });
      } finally {
        this.isLoading = false;
        if (isShowLoading) await this.$store.dispatch('base/SetLoading', false);
      }
    },
    async initData() {
      await this.getDetailData();
    }
  },
  filters: {},
  watch: {},
  created() {},
  mounted() {
    this.initData();
  },
  beforeRouteEnter(to, from, next) {
    // console.log(to)
    // console.log(from)
    next();
  },
  beforeRouteUpdate(to, from, next) {
    // console.log(to)
    // console.log(from)
    next();
  }
};